import React from 'react';
import PropTypes from 'prop-types';
import {
  NavLink,
  withRouter,
} from 'react-router-dom';
import SubMenu from './SubMenu';
import DesktopHeader from './styles';

import { H1 } from '../../common/Text/styles';

const DesktopHeaderComponent = ({
  location,
  regionalAdministrator,
}) => (
  <DesktopHeader>
    <div className="wrapper">
      <div className="text">
        <H1>My Profile</H1>
      </div>

      <div className="tabs-wrapper">
        <ul className="tabs">
          <li className="tab">
            <NavLink to="/my-profile/member-info" exact activeClassName="active-tab">
              MEMBER INFO
            </NavLink>
          </li>
          <li className="tab">
            <NavLink to="/my-profile/membership-details" exact activeClassName="active-tab">
              MEMBERSHIP DETAILS
            </NavLink>
          </li>
          <li className="tab">
            <NavLink to="/my-profile/my-vehicles" exact activeClassName="active-tab">
              MY VEHICLES
            </NavLink>
          </li>
          <li className="tab">
            <NavLink to="/my-profile/my-events" exact activeClassName="active-tab">
              MY EVENTS
            </NavLink>
          </li>
          <SubMenu
              className={`tab ${location.pathname.includes('search/') && 'active'}`}
              title="SEARCH"
              items={[
                {
                  href: '/my-profile/search/member',
                  title: 'MEMBER SEARCH',
                }, {
                  href: '/my-profile/search/vehicle',
                  title: 'VEHICLE SEARCH',
                },
              ]}
          />
          <li className="tab">
            <NavLink to="/my-profile/member-resources" exact activeClassName="active-tab">
              MEMBER RESOURCES
            </NavLink>
          </li>
          <li className={`tab ${!regionalAdministrator && 'hide'}`}>
            <NavLink to="/my-profile/regional-administrator" exact activeClassName="active-tab">
              REGIONAL REPORTS
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  </DesktopHeader>
);

DesktopHeaderComponent.propTypes = {
  location: PropTypes.object.isRequired,
  regionalAdministrator: PropTypes.bool,
};

DesktopHeaderComponent.defaultProps = {
  regionalAdministrator: false,
};

export default withRouter(DesktopHeaderComponent);
