import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { arrowDownRed, arrowUpRed } from '../../../../assets/images';
import VehicleSearch from './styles';

import {
  H4,
  Link,
  Paragraph,
} from '../../../../components/common/Text';
import VehicleSearchForm from '../../../../components/Forms/VehicleSearchForm';
import SearchResults from '../../../../components/SearchResults';
import TextButton from '../../../../components/common/TextButton';

import {
  searchVehiclesActions,
  searchVehiclesSelectors,
} from '../../../../store/ducks/searchVehicles';
import {
  vehiclesSelectors,
} from '../../../../store/ducks/vehicles';

const NO_RESULTS_TEXT = 'No results for your search. Please try again.';
const SEARCH_FORM_DESCRIPTION = 'Use this form to search for member cars';
const SEARCH_RESULTS_DESCRIPTION = 'Click on a car number in the left-hand column to see full car and member info. If there is more than one page of results, use the numbered links at the bottom of the list to page through results.';

const SORT_OPTIONS = [
  { name: 'year', title: 'Year' },
  { name: 'model', title: 'Model' },
];

const VehicleSearchPage = ({
  clearVehicleListResults,
  getVehiclesList,
  loading,
  makes,
  requestErrors,
  vehiclesSearchResults,
}) => {
  const {
    order,
    page,
    sorting,
    ...prevSearchParams
  } = JSON.parse(sessionStorage.getItem('vehicleSearchParams')) || {};
  sessionStorage.removeItem('vehicleSearchParams');

  const [resultPage, setResultPage] = useState(page || 1);
  const [searchParams, setSearchParams] = useState(
    Object.keys(prevSearchParams || {}).length ? prevSearchParams : {},
  );
  const [sortParameter, setSortParameter] = useState(
    (order && sorting) ? { order, sorting } : {},
  );

  useEffect(() => {
    if (Object.keys(searchParams).length) {
      getVehiclesList({
        ...searchParams,
        ...(Object.keys(sortParameter).length ? sortParameter : {}),
        page: resultPage,
      });
    }

    return clearVehicleListResults();
  }, [
    clearVehicleListResults,
    getVehiclesList,
    resultPage,
    searchParams,
    sortParameter,
  ]);

  const changeSortParameter = useCallback((propName) => {
    setSortParameter({
      sorting: propName,
      order: (sortParameter.sorting === propName && sortParameter.order === 'desc') ? 'asc' : 'desc',
    });
  }, [sortParameter]);

  const renderSortButtons = useCallback(() => (
    <div className="sort-buttons">
      {SORT_OPTIONS.map(e => (
        <div
          key={e.name}
          className={`sort-btn ${sortParameter.sorting === e.name && 'active-sort'}`}
        >
          <TextButton
            onClick={() => changeSortParameter(e.name)}
            title={`Sort by ${e.title}`}
          />
          <img src={sortParameter.order === 'asc' ? arrowUpRed : arrowDownRed} alt="" />
        </div>
      ))}
    </div>
  ), [changeSortParameter, sortParameter]);

  const renderRow = useCallback((value, className = '') => (
    value && <Paragraph className={className}>{value}</Paragraph>
  ), []);

  const renderItem = useCallback(({
    firstName,
    id,
    lastName,
    makeId,
    model,
    numberOfCylinders,
    state,
    year,
  }) => (
    <li key={id} className="item">
      <div className="column">
        <Link
          href={`/my-profile/search/vehicle/${id}`}
          onClick={() => {
            const memberSearchParams = {
              ...searchParams,
              ...(Object.keys(sortParameter).length ? sortParameter : {}),
              page: resultPage,
            };
            sessionStorage.setItem('vehicleSearchParams', JSON.stringify(memberSearchParams));
          }}
        >
          {`No. ${id}`}
        </Link>
        {renderRow(`${firstName} ${lastName}`)}
        {renderRow(`Location: ${state || '-'}`)}
      </div>
      <div className="column">
        {renderRow(
          `Make: ${((makes || []).find(e => e.id === makeId) || {}).name}`,
        )}
        {renderRow(`Model: ${model}`)}
      </div>
      <div className="column">
        {renderRow(`Year: ${year}`)}
        {renderRow(`Cylinders: ${numberOfCylinders}`)}
      </div>
    </li>
  ), [
    makes,
    renderRow,
    resultPage,
    searchParams,
    sortParameter,
  ]);

  const renderList = useCallback(data => (
    <ul className="list">
      {(data || []).map(renderItem)}
    </ul>
  ), [renderItem]);

  return (
    <VehicleSearch>
      <div className="vehicle-search-container">
        <H4 className="title">Car Search</H4>
        <Paragraph className="msg-text">{SEARCH_FORM_DESCRIPTION}</Paragraph>
        <VehicleSearchForm
          initData={searchParams}
          onReset={() => {
            clearVehicleListResults();
            setSearchParams({});
            setSortParameter({});
            setResultPage(1);
          }}
          onSubmit={(params) => {
            setSearchParams(params);
            setSortParameter({});
            setResultPage(1);
          }}
          requestErrors={requestErrors}
        />
        {vehiclesSearchResults.results && (
          <SearchResults
            initPage={resultPage}
            listDescription={SEARCH_RESULTS_DESCRIPTION}
            loading={loading}
            onChangePage={newPage => setResultPage(newPage)}
            totalResults={vehiclesSearchResults.total}
          >
            {(vehiclesSearchResults.total === 0)
              ? (
                <Paragraph className="no-results">{NO_RESULTS_TEXT}</Paragraph>
              ) : (
                <>
                  {renderSortButtons()}
                  {renderList(vehiclesSearchResults.results)}
                </>
              )}
            </SearchResults>
        )}
      </div>
    </VehicleSearch>
  );
};

VehicleSearchPage.propTypes = {
  clearVehicleListResults: PropTypes.func.isRequired,
  getVehiclesList: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  makes: PropTypes.array,
  requestErrors: PropTypes.object,
  vehiclesSearchResults: PropTypes.object,
};

VehicleSearchPage.defaultProps = {
  loading: false,
  makes: null,
  requestErrors: {},
  vehiclesSearchResults: {},
};

const mapStateToProps = state => ({
  loading: searchVehiclesSelectors.selectLoadingVehiclesList(state),
  makes: vehiclesSelectors.selectMakes(state),
  requestErrors: searchVehiclesSelectors.selectVehiclesListErrors(state),
  vehiclesSearchResults: searchVehiclesSelectors.selectVehiclesSearchResults(state),
});

const mapDispatchToProps = {
  clearVehicleListResults: searchVehiclesActions.clearVehicleListResults,
  getVehiclesList: searchVehiclesActions.getVehiclesList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VehicleSearchPage);
